<template>
  <div class="container">
    <div class="nac">
      <van-icon @click="goback" size=".35rem" name="arrow-left" />
      <div class="xxm">完善简历</div>
    </div>
    <div class="identity">
      <p class="tian">填写身份信息</p>
      <p class="buddha">
        <span>头像</span>
        <!-- 女孩 -->
        <img
          class="icon"
          v-if="become == '女'"
          src="../../assets/nv.jpg"
          alt=""
        />
        <!-- 男孩 -->
        <img
          class="icon"
          v-if="become == '男'"
          src="../../assets/user_1.jpg"
          alt=""
        />
      </p>
      <div class="sexs">
        <span class="se ss">性别</span>
        <p class="sex">
          <span
            class="nan"
            :class="{ select: become == '男', select1: become == '女' }"
            @click="updatesex('男')"
            >男</span
          >
          <span
            class="nv"
            :class="{ select: become == '女', select1: become == '男' }"
            @click="updatesex('女')"
            >女</span
          >
        </p>
      </div>

       <p class="buddha">
        <span class="sr ss">户籍所在地</span>
        <input
          class="lian"
          type="text"
          v-model="hj"
          placeholder="请输入户籍所在地"
          style="text-align: right; width: 5rem"
        />
      </p>

        <!-- 户籍
      <van-popup v-model="show1" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm5"
          @cancel="show1 = false"
          :columns-placeholder="['请选择', '全部', '全部']"
        />
      </van-popup> -->

      <!-- <p class="buddha">
        <span class="sr ss">姓名</span>
        <input
          type="text"
          class="lian"
          v-model="name"
          style="text-align: right"
          placeholder="请填写姓名"
        />
      </p> -->

      <p class="buddha">
        <span class="sr ss">联系邮箱</span>
        <input
          type="text"
          class="lian"
          v-model="mailbox"
          style="text-align: right"
          placeholder="请填写邮箱"
        />
      </p>

      <p class="buddha">
        <span class="sr ss">期望薪资(元)</span>
        <input
          readonly="readonly"
          class="lian xx"
          id="phone"
          type="text"
          style="text-align: right"
          v-model="pay"
          @click="show = true"
          placeholder="请选择期望薪资"
        />
      </p>
      
      <van-action-sheet v-model="show" title="">
        <div class="content">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </div>
      </van-action-sheet>
    </div>
    <div>
      <p class="btn1" @click="formcheck">下一步</p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Addressinfo from "../../util/Area";
export default {
  data() {
    return {
      mailbox: "", // 邮箱
      show: false, // 控制薪资弹出框显示隐藏
      show1:false,
      columns: [
        "面议",
        "5000-10000(元)",
        "10000-15000(元)",
        "15000-20000(元)",
        "20000-25000(元)",
        "25000-30000(元)",
        "30000-35000(元)",
        "35000-40000(元)",
        "40000-50000(元)",
        "50000-60000(元)",
        "60000以上",

      ],
      become: "", //性别 1男  2女
      pay: "", //薪资范畴
      // name:"",//姓名
      hj:"", // 户籍
      areaList: Addressinfo,
    
    };
  },
  mounted() {
    if(sessionStorage.getItem("resumeType") == 0){

      this.become = sessionStorage.getItem("sex") || "男"
      this.pay = sessionStorage.getItem("salary") || "";
      this.hj = sessionStorage.getItem("hj") || ""
      // console.log(this.pay)
      // this.mailbox = sessionStorage.getItem("email");
    }else{
      let perResume = JSON.parse(sessionStorage.getItem("perResume")) 
      let sex = perResume.sex
      if(sex == ""){
        sex = "男"
      }
      this.hj = sessionStorage.getItem("hj") || perResume.household_register
      this.become = sessionStorage.getItem("sex") ||  sex
      // this.name = sessionStorage.getItem("name") ||  perResume.name
      this.mailbox = sessionStorage.getItem("mailbox") ||  perResume.email
      this.pay = sessionStorage.getItem("salary") || perResume.salary
      // this.mailbox = sessionStorage.getItem("email") || perResume.email
    }
  },
  methods: {
    //打开求职区域插件
    showPopup() {
      this.show1 = true;
    },
    // onConfirm5(value){
    //  this.show1 = false;
    // //  console.log(value)
   
    //   let b = "";
    //   value.map((item)=>{
    //     if(item!= undefined){
    //        b+=item.name
    //     }
        
    //   })
    //  this.hj = b
    //   sessionStorage.setItem("hj",b)
    
   
     
    // },
    updatesex(type){
      this.become = type

      console.log(type)
    },
    goback() {
      this.$router.push("./userResume");
    },
    //确认选择薪资
    onConfirm(value) {
      // console.log(value);
      sessionStorage.setItem("salary", value);
      this.pay = value;
      this.show = false;
    },
    //取消选择薪资
    onCancel() {
      this.show = false;
    },
    //保存进入下一步
    formcheck() {
      let {  become, pay,  mailbox,hj} = this;
      // console.log(hj)
       if(hj == ""){
         Toast("请选择户籍");
        return;
      }
      if (mailbox == "") {
        Toast("请输入邮箱");
        return;
      }
     
      if (pay == "") {
        Toast("请选择期望薪资");
        return;
      }
      sessionStorage.setItem("sex", become);
      sessionStorage.setItem("hj", hj);
      sessionStorage.setItem("email", mailbox);
      sessionStorage.setItem("salary" ,pay)
      this.$router.push("./resume");
    },
  },
};
</script>

<style scoped>
.xxm {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
}

.nac > :first-child {
  margin-left: 0.32rem;
}

.nac {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  padding: 0.3rem 0;
  position: relative;
  border-bottom-color: #ebedf0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
input {
  outline: none;
  width: 4.5rem !important;
}
input:disabled {
  background-color: #fff;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}

.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}

.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 3.07rem;
}

.tian {
  font-size: 0.45rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  margin-top: 0.38rem;
}

.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  /* display: flex; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .icon {
  float: right;
  width: 1.08rem;
  height: 1.08rem;
  margin: 0.19rem 0rem;
}

.identity .buddha .lian {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.identity .sexs {
  display: flex;
  height: 1.46rem;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;

}

.identity .se {
  margin-top: 0.52rem;
}

.identity .sexs .sex {
  width: 3.07rem;
  height: 0.74rem;
  border: solid 0.01rem #f55613;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f55613;
  margin-top: 0.36rem;
  line-height: 0.74rem;
  margin-left: 2.9rem;
  display: flex;
}

.identity .sexs .sex .nv {
  display: block;
  width: 1.54rem;
  color: #ffffff;

  text-align: center;
}
.select {
  color: #fff;
  background: #f55613;
}
.select1 {
  color: #f55613 !important;
  background: #fff;
}
.identity .sexs .sex .nan {
  display: block;
  width: 1.54rem;
  text-align: center;
}

.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}

.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
}

.btn1 {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.5rem ;
  margin-left: 0.34rem;
}
</style>
